import { Link } from "react-router-dom"
import { OpenerItem } from "../../../../component/OpenerItem"
import { QuestionsForm } from "../../../../component/QuestionsForm"

export const Questions = () => {
    const questions_list = [
        {
            title: "Будут ли выдаваться баллы НМО?",
            desc: (<>Скоро на наших курсах появится возможность получать баллы непрерывного медицинского образования. Следите за новостями на сайте <Link to="https://alsfund.ru" target="_blank">alsfund.ru</Link> или в соцсетях фонда</>)
        },
        {
            title: "На какое время будет предоставляться доступ?",
            desc: "Доступ к курсу после покупки останется у вас даже после прохождения"
        },
        {
            title: "Будут ли очные программы?",
            desc: (<>Фонд «Живи сейчас» регулярно проводит очные образовательные мероприятия для медицинских специалистов. Следите за новостями на сайте <Link to="https://alsfund.ru" target="_blank">alsfund.ru</Link> или в соцсетях фонда</>)
        },
        {
            title: "Есть ли еще какие-либо курсы у фонда?",
            desc: "Мы постоянно работаем над тем, чтобы создавать больше образовательных проектов, подписывайтесь на соцсети фонда «Живи сейчас», чтобы следить за актуальной информацией. Наши курсы мы будем публиковать здесь"
        },
        // {
        //     title: "Будет ли поддержка в процессе обучения?",
        //     desc: "Вы всегда сможете задать вопросы в онлайн-чате курса в личном кабинете"
        // },
        {
            title: "Как получить сертификат?",
            desc: "Сертификат смогут получить на почту все учащиеся, кто правильно ответит на 100% вопросов и больше"
        }
    ]

    return(
        <section className="questions">
            <div className="content">
                <div className="questions-wrapper">
                    <h3 className="block-title">Часто задаваемые вопросы</h3>
                    <div className="questions-list">
                        {
                            questions_list.map((item, index) => {
                                return(
                                    <OpenerItem 
                                        title={<div className="questions-item__title">{item.title}</div>} 
                                        desc={
                                            <div className="questions-item__description mini-paragraf">{item.desc}</div>
                                        }
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <QuestionsForm />
            </div>
        </section>
    )
}