import timeicon from '../../images/time-icon.png'
import typeicon from '../../images/type-icon.png'

import circle01 from '../../images/circle_01.png'
import circle02 from '../../images/circle_02.png'
import circle03 from '../../images/circle_03.png'
import circle04 from '../../images/circle_04.png'
import circle05 from '../../images/circle_05.png'
import circle06 from '../../images/circle_06.png'

import howstaps from '../../images/how-staps.svg'
import descicon from '../../images/desc-icon.png'

import buyericon1 from '../../images/buyer-icon1.png'
import buyericon2 from '../../images/buyer-icon2.png'
import buyericon3 from '../../images/buyer-icon3.png'

import certificates from '../../images/certificates.png'

import arrowleft from '../../images/arrow_left.svg'
import arrowright from '../../images/arrow_right.svg'

import { Link, useParams } from 'react-router-dom'
import { CoursesSlider } from '../../component/CoursesSlider'
import { ReviewsList, Course, hostImg } from '../../classes'
import { ReviewsSlider } from '../../component/ReviewsSlider'
import { ReviewsPopup } from '../../component/ReviewsPopup'
import { useContext, useEffect, useState } from 'react' 
import { Loader } from '../../component/Loader'
import Slider from 'react-slick'
import { GlobalContext } from '../../context/GlobalContext'
import { CourseModule } from '../../component/CourseModule'
import { Helmet } from 'react-helmet'
import { PopUpNatification } from '../../component/PopUpNatification'

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <img 
            className={className}
            style={{width: 50, height: 50, zIndex: 2}}
            src={arrowright} 
            onClick={onClick}
        />
    );
}
  
function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <img 
            className={className}
            style={{width: 50, height: 50, zIndex: 2}}
            src={arrowleft} 
            onClick={onClick}
        />
    );
}

export const CoursePage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const { pk } = useParams()
    const [openReview, setOpenReview] = useState(false)
    const [course, setCourse] = useState(new Course)
    const [courseFavorite, setCourseFavorite] = useState(false)
    const [courseInCart, setCourseInCart] = useState(false)
    const [popUpItemAdd, setPopUpItemAdd] = useState({
        open: false,
        text: '"Название" успешно добавлен в корзину/избранное'
    })

    const CourseStatus = function(){
        let flag = true
        course.module_course?.forEach((item, index) => {
            if(auth.user.module.indexOf(item.pk) == -1 && auth.user.cart.indexOf(item.pk) == -1){
                flag = false
            }
        })
        return flag
    }

    const CourseToCart = () => {
        if(!courseInCart){
            setPopUpItemAdd({
                text: `Курс "${course.title}" успешно добавлен в корзину`,
                open: true
            })
            course.module_course?.forEach((item, index) => {
                if(auth.user.module.indexOf(item.pk) == -1){
                    if(auth.user.cart.indexOf(item.pk) == -1){
                        auth.user.cart.push(item.pk)
                    }
                }
            })
            auth.user.setCart()
        }
        setCourseInCart(CourseStatus())
    }

    useEffect(()=>{
        if(course.pk){
            setLoad(true) 
            course.getData(pk!).then(function(response){
                if(response){
                    window.location.replace("/");
                }else{
                    setCourseFavorite(auth.user.favoriteCourses.includes(course.pk))
                    setCourseInCart(CourseStatus())
                    setLoad(false)
                }
            }).catch(function(error){
                alert("error")
            })
        }
    },[])

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <Helmet>
                <title>{course.title} - Образовательный портал БФ «Живи сейчас»</title>
                <meta
                    name="description"
                    content='Благотворительный фонд «Живи сейчас» обучает медицинских и социальных работников паллиативному уходу за пациентами с диагнозом БАС и иным паллиативным статусом'
                />
            </Helmet>
            <section className="course-banner">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="grid">
                                <div>
                                    <h1 className='block-title'>{course.title}</h1>
                                    <div className="course-banner__description">
                                        {course.miniDesc}
                                    </div>    
                                    <div className="course-banner__icons">
                                        <div className="">
                                            <div className="course-banner__icons-wrapper" style={{backgroundImage: `url('${timeicon}')`}}>
                                                <span>Длительность</span>
                                                {course.duration}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="course-banner__icons-wrapper" style={{backgroundImage: `url('${typeicon}')`}}>
                                                <span>Тип</span>
                                                {course.type}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="course-banner__buttons">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <button 
                                                    className="orange-button"
                                                    onClick={()=>{
                                                        CourseToCart()
                                                    }}
                                                >
                                                    {courseInCart ? "Курс уже в корзине":
                                                        course.cost > 0 ? "Купить курс" :
                                                        "Начать обучение"
                                                    }
                                                </button>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="grid">
                                                    <button 
                                                        style={{height: "70px"}} 
                                                        className={`course-banner__button-like ${courseFavorite ? "active":""}`}
                                                        onClick={()=>{
                                                            if(!courseFavorite){
                                                                setPopUpItemAdd({
                                                                    text: `Курс "${course.title}" успешно добавлен в избранное`,
                                                                    open: true
                                                                })
                                                                setCourseFavorite(true)
                                                                auth.user.favoriteCourses.push(course.pk)
                                                            }else{
                                                                setCourseFavorite(false)
                                                                const index = auth.user.favoriteCourses.indexOf(course.pk)
                                                                if (index !== -1) {
                                                                    auth.user.favoriteCourses.splice(index, 1)
                                                                }
                                                            }
                                                            auth.user.setFavorite()
                                                        }}
                                                    >
                                                        <svg style={{top: 4}} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.70862 20.1781L10.1729 19.5891L9.70862 20.1781ZM13.0001 5.65036L12.4597 6.17046C12.6011 6.31736 12.7962 6.40036 13.0001 6.40036C13.204 6.40036 13.3991 6.31736 13.5404 6.17046L13.0001 5.65036ZM16.2915 20.1781L16.7558 20.7671L16.2915 20.1781ZM10.1729 19.5891C8.53469 18.2977 6.73233 17.0286 5.30316 15.4193C3.8997 13.8389 2.91675 11.9899 2.91675 9.58987H1.41675C1.41675 12.458 2.61098 14.6467 4.18159 16.4153C5.72648 18.1549 7.69506 19.5458 9.24431 20.7671L10.1729 19.5891ZM2.91675 9.58987C2.91675 7.23638 4.2467 5.25951 6.06715 4.42738C7.84032 3.61686 10.2142 3.83751 12.4597 6.17046L13.5404 5.13026C10.9111 2.39845 7.86835 1.95477 5.44356 3.06315C3.06605 4.14991 1.41675 6.67518 1.41675 9.58987H2.91675ZM9.24431 20.7671C9.79981 21.205 10.3922 21.6687 10.9915 22.019C11.5906 22.369 12.268 22.6497 13.0001 22.6497V21.1497C12.6488 21.1497 12.2429 21.0129 11.7483 20.7239C11.2539 20.435 10.7422 20.0379 10.1729 19.5891L9.24431 20.7671ZM16.7558 20.7671C18.3051 19.5458 20.2737 18.1549 21.8186 16.4153C23.3892 14.6467 24.5834 12.458 24.5834 9.58987H23.0834C23.0834 11.9899 22.1005 13.8389 20.697 15.4193C19.2678 17.0286 17.4655 18.2977 15.8272 19.5891L16.7558 20.7671ZM24.5834 9.58987C24.5834 6.67518 22.9341 4.14991 20.5566 3.06315C18.1318 1.95477 15.0891 2.39845 12.4597 5.13026L13.5404 6.17046C15.7859 3.83751 18.1598 3.61686 19.933 4.42738C21.7535 5.25951 23.0834 7.23638 23.0834 9.58987H24.5834ZM15.8272 19.5891C15.2579 20.0379 14.7462 20.435 14.2519 20.7239C13.7573 21.0129 13.3513 21.1497 13.0001 21.1497V22.6497C13.7321 22.6497 14.4096 22.369 15.0087 22.019C15.608 21.6687 16.2004 21.205 16.7558 20.7671L15.8272 19.5891Z" fill="#7A7A8A" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5" style={{textAlign: "right"}}>
                            <img src={`${hostImg}${course.imageDetail}`} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="course-about">
                <div className="content">
                    <h2 className="block-title">О чём курс</h2>
                    <div className='left paragraf' dangerouslySetInnerHTML={{ __html: course.about! }} />
                </div>
            </section>

            <section className="advantages">
                <div className="content">
                    <h2 className="block-title center">Кому подойдёт курс?</h2>
                    <div className="row">
                        {
                            course.suitable_course?.length ? (
                                course.suitable_course.map((item, index) => {
                                    return(
                                        <div className="col-md-6 col-lg-4">
                                            <div className="advantages-item__wrapper">
                                                <img src={item.image} height={86} />
                                                <h3 className='item-title'>{item.title}</h3>
                                                <p className='mini-paragraf'>{item.miniDesc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            ):""
                        }
                    </div>
                </div>
            </section>

            <section className="course-module">
                <div className="content">
                    <div className="course-module__wrapper">
                        <h2 className="block-title">Программа курса</h2>
                        <div className="course-module__list">  
                            {
                                course.module_course?.length ? (
                                    course.module_course.map((item, index) => {
                                        return(
                                            <CourseModule
                                                item={item}
                                                index={index}
                                                setCourseInCart={setCourseInCart}
                                                CourseStatus={CourseStatus}    
                                                setPopUpItemAdd={setPopUpItemAdd}
                                            />
                                        )
                                    })
                                ):""
                            } 
                        </div>
                    </div>
                </div>
            </section>

            {
                course.cost != 0 && course.descPercent > 0 ? (
                    <section className="course-order-info">
                        <div className="content">
                            <div className="course-order-info__block">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="course-order-info__wrapper" style={{height: "100%"}}>
                                            <h2 className="block-title">Курс целиком</h2>
                                            <div className="course-order-info__discount">
                                                <div className="course-order-info__discount-header center">
                                                    <img src={descicon} />
                                                    {
                                                        course.blueText ? 
                                                            course.blueText : 
                                                            (
                                                                <>
                                                                    Выгоднее на <b>{course.descPercent}%</b>, чем по частям
                                                                </>
                                                            )
                                                    }
                                                </div>
                                                <div className="course-order-info__discount-body">
                                                    <h3 className='course-order-info__discount-amount'>
                                                        <span>{course.cost}₽</span>
                                                        {course.cost - course.cost * (course.descPercent / 100)}₽
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="course-order-info__wrapper">
                                            <p className="course-order-info__pack paragraf" dangerouslySetInnerHTML={{ __html: course.discountDesc }}></p>
                                            <div className="course-order-info__icons">
                                                <img src={buyericon1} style={{marginRight: 10}} />
                                                <img src={buyericon2} style={{marginRight: 10}} />
                                                <img src={buyericon3} />
                                            </div> 
                                            <div className="course-order-info__buy-btn">
                                                <button className='orange-button' onClick={() => {CourseToCart()}}>
                                                    {courseInCart ? "Курс уже в корзине":"Начать обучение"}
                                                </button> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ):""
            }
            
            <section className="take-care">
                <div className="content">
                    <div className="take-care__wrapper">
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className='block-title'>Заботьтесь</h2>
                                <h3 className='item-title'>и вдохновляйтесь вместе с нами!</h3>
                                <p className='paragraf'>Не упустите возможность стать профессионалом, делающим значимый вклад в заботу о людях с БАС.  Записывайтесь прямо сейчас и станьте светлым лучом в жизни тех, кто нуждается в вашей заботе!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-learn">
                <div className="content">
                    <h2 className="block-title">Чему вы научитесь</h2>
                    <div className="what-learn__wrapper row">
                        {
                            course.learn?.learn1 ? (
                                <div className="col-md-6">
                                    <div className="what-learn__item" style={{backgroundImage: `url('${circle01}')`}}>
                                        <div className="grid">
                                            <div className='paragraf'>{course.learn?.learn1}</div>
                                        </div>
                                    </div>
                                </div>
                            ):""
                        } 
                        {
                            course.learn?.learn2 ? (
                                <div className="col-md-6">
                                    <div className="what-learn__item" style={{backgroundImage: `url('${circle02}')`}}>
                                        <div className="grid">
                                            <div className='paragraf'>{course.learn?.learn2}</div>
                                        </div>
                                    </div>
                                </div>
                            ):""
                        } 
                        {
                            course.learn?.learn3 ? (
                                <div className="col-md-6">
                                    <div className="what-learn__item" style={{backgroundImage: `url('${circle03}')`}}>
                                        <div className="grid">
                                            <div className='paragraf'>{course.learn?.learn3}</div>
                                        </div>
                                    </div>
                                </div>
                            ):""
                        } 
                        {
                            course.learn?.learn4 ? (
                                <div className="col-md-6">
                                    <div className="what-learn__item" style={{backgroundImage: `url('${circle04}')`}}>
                                        <div className="grid">
                                            <div className='paragraf'>{course.learn?.learn4}</div>
                                        </div>
                                    </div>
                                </div>
                            ):""
                        } 
                        {
                            course.learn?.learn5 ? (
                                <div className="col-md-6">
                                    <div className="what-learn__item" style={{backgroundImage: `url('${circle05}')`}}>
                                        <div className="grid">
                                            <div className='paragraf'>{course.learn?.learn5}</div>
                                        </div>
                                    </div>
                                </div>
                            ):""
                        } 
                        {
                            course.learn?.learn6 ? (
                                <div className="col-md-6">
                                    <div className="what-learn__item" style={{backgroundImage: `url('${circle06}')`}}>
                                        <div className="grid">
                                            <div className='paragraf'>{course.learn?.learn6}</div>
                                        </div>
                                    </div>
                                </div>
                            ):""
                        } 
                    </div>
                </div>
            </section>

            <section className="how-training">
                <div className="content">
                    <h2 className="block-title">Как проходит обучение</h2>
                    <div className="row how-training__staps">
                        <div className="col-md-4 col-lg-five">
                            <div className="how-training__staps-number">Шаг 1</div>
                            <img src={howstaps} />
                            <h3 className='item-title'>Регистрация</h3>
                            <p className='mini-paragraf'>
                                Нужна для получения доступа к личному кабинету. Юрлица для корпоративного обучения могут 
                                оформить доступ к ЛК через администратора.
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-five">
                            <div className="how-training__staps-number">Шаг 2</div>
                            <img src={howstaps} />
                            <h3 className='item-title'>Выбор курса</h3>
                            <p className='mini-paragraf'>
                                В каталоге курсов выбрать нужный продукт и положить в корзину. 
                                Начать обучение можно сразу после оплаты.
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-five">
                            <div className="how-training__staps-number">Шаг 3</div>
                            <img src={howstaps} />
                            <h3 className='item-title'>Оплата</h3>
                            <p className='mini-paragraf'>
                                Банковской картой онлайн из корзины или через выставление счета 
                                администратором после предварительного согласования.
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-five">
                            <div className="how-training__staps-number">Шаг 4</div>
                            <img src={howstaps} />
                            <h3 className='item-title'>Прохождение обучения</h3>
                            <p className='mini-paragraf'>
                                Курс и поддержка администраторов находятся в личном кабинете. 
                                Программа может содержать дистанционную и очную части, тесты, задания.
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-five">
                            <div className="how-training__staps-number">Шаг 5</div>
                            <img src={howstaps} />
                            <h3 className='item-title'>Итоговый документ</h3>
                            <p className='mini-paragraf'>
                                По завершении обучения выдается документ: сертификат, удостоверение, диплом. 
                                Важно указать настоящее имя, на которое он будет выписан.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="course-teacher">
                <div className="content">
                    <h2 className="block-title">Преподаватели курса</h2>
                    <h3 className="course-teacher__description">Обучение ведут практикующие специалисты</h3>

                    {
                        course.teachers ?(
                            <Slider {
                                ...{
                                    dots: true, 
                                    infinite: course.teachers!.length > 3 ? true : false, 
                                    speed: 500, 
                                    slidesToShow: 3, 
                                    slidesToScroll: 1, 
                                    arrows: course.teachers!.length > 3 ? true : false, 
                                    nextArrow: <SampleNextArrow />, 
                                    prevArrow: <SamplePrevArrow />,
                                    responsive: [
                                        {
                                            breakpoint: 1024,
                                            settings: {
                                                slidesToShow: 3,
                                                slidesToScroll: 1,
                                            }
                                        },
                                        {
                                            breakpoint: 960,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 1,
                                                arrows: false
                                            }
                                        },
                                        {
                                            breakpoint: 760,
                                            settings: {
                                                slidesToShow: 1,
                                                slidesToScroll: 1,
                                                arrows: false
                                            }
                                        }
                                    ]
                                }
                            }>
                                {
                                    course.teachers?.length ? (
                                        course.teachers?.map((item, index) => {
                                            return(
                                                <div>
                                                    <div className="course-teacher__wrapper">
                                                        <div className="course-teacher__image" style={{backgroundImage: `url('${hostImg}${item.image}')`}}></div>
                                                        <div className="course-teacher__content">
                                                            <h3 className='item-title'>{item.title}</h3>
                                                            <p className='mini-paragraf'>{item.desc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </Slider> 
                        ):""
                    }
                </div>
            </section>


            <section className="certificates">
                <div className="content">
                    <div className="row">
                        <div className="col-md-4 col-lg-3"></div>
                        <div className="col-md-8 col-lg-9">
                            <div className="certificates__wrapper">
                                <img src={course.certificate_image != "0" ? course.certificate_image : certificates} />
                                <h2 className="block-title">
                                    {course.certificate_title != "0" ? course.certificate_title : "Вы получите сертификат"}
                                </h2>
                                <p className='paragraf'>
                                    {
                                        course.certificate_description != "0" ? 
                                        course.certificate_description : 
                                        "После окончания интенсива и успешной сдачи всех домашних работ"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="reviews">
                <div className="content">  
                    <h2 className="block-title center">
                        Отзывы
                        <span onClick={()=>{setOpenReview(true)}}>
                            Смотреть все отзывы
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 10L10 1M10 1H2.46939M10 1V8.53061" stroke="#F26421" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </h2> 
                    <ReviewsSlider course={pk} />
                </div> 
            </section>

            {
                openReview ? (
                    <ReviewsPopup setOpenReview={setOpenReview} course={pk} />
                ):""
            }
            
            <section className="course our-courses">
                <div className="content">
                    <h2 className="block-title">
                        У нас есть еще<br/>
                        интересные курсы:
                    </h2>
                    <CoursesSlider /> 
                </div>
            </section>


            {
                popUpItemAdd.open && (
                    <PopUpNatification 
                        text={popUpItemAdd.text}
                        setPopUpItemAdd={setPopUpItemAdd}
                    />
                )
            }
        </>
    )
}