import axios from "axios"
import logo from '../../images/logo.svg'
import lens from '../../images/lens.svg'
import book from '../../images/book.svg'
import like from '../../images/like.svg'
import cart from '../../images/cart.svg'
import user from '../../images/user.svg'
import { GlobalContext } from '../../context/GlobalContext'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '../Loader'
import close from '../../images/close.svg'
import { host } from "../../classes"
import { ContactFormSender } from "../../utils"

export const Header = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [cartCount, setCartCount] = useState(0)

    const queryParams = new URLSearchParams(window.location.search)
    const [search, setSearch] = useState(`${queryParams.get('s') ? queryParams.get('s') : ""}`)

    const [popupSubscribe, setPopupSubscribe] = useState(false)
    const [form, setForm] = useState({
        name: "",
        phone: "",
        email: "",
    })
    const [formSend, setFormSend] = useState(false)

    const SubmitForm = async(event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        ContactFormSender(form).then(function(response){
            setForm({
                name: "",
                phone: "",
                email: "",
            })
            setLoad(false)
            setFormSend(true)
        })
    }

    useEffect(()=>{
        if(auth.user.userToken != ""){
            auth.user.LoadData().then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }else{
            setLoad(false)
        }
    },[])

    useEffect(() => {
        const timer = setInterval(() => {
            setCartCount(auth.user.cart.length)
        }, 1000);
        
        // очистка интервала
        return () => clearInterval(timer);
    });

    useEffect(()=>{
        console.log("adsa")
    },[auth.user.cart])

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            {
                popupSubscribe ? (
                    <div className='popup-form'>
                        <div>
                            <div className="popup-info__close">
                                <img src={close} onClick={()=>{
                                    setPopupSubscribe(false)
                                }} />
                            </div>
                            {
                                !formSend ? (
                                    <form onSubmit={SubmitForm}>
                                        <p className='popup-form__description'>Укажите номер телефона для получения обратного звонка</p>
                                        <label>
                                            <input 
                                                type="text" 
                                                placeholder='Имя' 
                                                value={form.name}
                                                required
                                                onChange={(e)=>{
                                                    setForm({...form, name: e.target.value})
                                                }}
                                            />
                                        </label>
                                        <label>
                                            <input 
                                                type="email" 
                                                placeholder='Email' 
                                                value={form.email}
                                                required
                                                onChange={(e)=>{
                                                    setForm({...form, email: e.target.value})
                                                }}
                                            />
                                        </label>
                                        <label>
                                            <input 
                                                type="text" 
                                                placeholder='Номер телефона' 
                                                value={form.phone}
                                                required
                                                onChange={(e)=>{
                                                    setForm({...form, phone: e.target.value})
                                                }}
                                            />
                                        </label>
                                        <button className="orange-button">
                                            Заказать
                                        </button>
                                    </form>
                                ):(
                                    <form onSubmit={SubmitForm}>
                                        <p 
                                            className='popup-form__description'
                                            style={{
                                                fontSize: 24,
                                                textAlign: "center"
                                            }}
                                        >
                                            Заявка успешно отправлена
                                        </p>
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }

            <header>
                <div className="content">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="row">
                                <div className="col-6">
                                    <Link to="/" className="grid">
                                        <img className="logo" src={logo} />
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <div className="grid">
                                        <p className="contact-wrapper">
                                            <span>
                                                <Link className="header-email" to="mailto:kurs@als-info.ru">kurs@als-info.ru</Link>
                                            </span><br/>
                                            <Link className="header-phone" to="tel:+74953637174">+7 (495) 363-71-74</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className='header-search__wrapper'>
                                <form className="header-search" action={'/courses/'}>
                                    <button>
                                        <img src={lens} />
                                    </button>
                                    <input type="text" name="s" placeholder="Поиск" value={search} onChange={(e)=>{setSearch(e.target.value)}} />
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="grid">
                                <div className="flex-box">
                                    <button className='orange-button' onClick={()=>{setPopupSubscribe(true)}}>
                                        Заказать звонок
                                    </button>
                                    <div className="header-buttons__item">
                                        <div className="grid">
                                            <Link to="/courses/" className="header-buttons__item-image" style={{backgroundImage: `url('${book}')`}}>
                                                <span>Каталог</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="header-buttons__item">
                                        <div className="grid">
                                            <Link to="/favourites/" className="header-buttons__item-image" style={{backgroundImage: `url('${like}')`}}>
                                                <span>Избранное</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="header-buttons__item">
                                        <div className="grid">
                                            <Link to="/order/" className="header-buttons__item-image" style={{backgroundImage: `url('${cart}')`}}>
                                                {
                                                    cartCount > 0 ? (
                                                        <div className="cart-count">
                                                            {auth.user.cart.length}
                                                        </div>
                                                    ):""
                                                }
                                                <span>Корзина</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="header-buttons__item">
                                        <div className="grid">
                                            <Link to={`${auth.user.userToken != "" ? "/account/":"/login/"}`} className="header-buttons__item-image" style={{backgroundImage: `url('${user}')`}}>
                                                <span>
                                                    {`${auth.user.userToken != "" ? "Аккаунт":"Войти"}`}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}