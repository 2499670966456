import axios from "axios"
import footerlogo from '../../images/logo.svg'
import footerPartner from '../../images/f_part.png'
import footervk from '../../images/footer-vk.svg'
import footertg from '../../images/footer-tg.svg'
import footerok from '../../images/footer-ok.svg'
import footerrurube from '../../images/footer-rurube.png'
import close from '../../images/close.svg'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { host } from "../../classes"
import { Loader } from "../Loader"

export const Footer = () => {
    const [popupSubscribe, setPopupSubscribe] = useState(false)
    const [email, setEmail] = useState("")
    const [formSend, setFormSend] = useState(false)
    const [load, setLoad] = useState(false)

    const SubmitForm = async(event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        await axios.post(`${host}subscribe/`, {
            email: email
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {  
            setEmail("")
            setLoad(false)
            setFormSend(true)
        }).catch(function (error) { 
            setEmail("")
            setLoad(false)
            setFormSend(true)
        }) 
    }

    if(load)
        return(
            <Loader />
        )

    return(
        <>
            <footer>
                <div className="content">
                    <div className="row footer-menu">
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-6 col-md-12">
                                    <Link to="https://alsfund.ru/" target="_blank">
                                        <img className="form-logo" src={footerlogo} />
                                    </Link>
                                </div>
                                <div className="col-6 col-md-12">
                                    <div className="footer-partner">
                                        <div>в партнерстве с</div>
                                        <a href="https://allnurses.ru/" target="_blank">
                                            <img src={footerPartner} />
                                        </a>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h3>Навигация</h3>
                            <ul>
                                <li>
                                    <Link to="https://alsfund.ru/" target='_blank'>Основной сайт фонда «Живи сейчас»</Link>
                                </li>
                                <li>
                                    <Link to="https://als-info.ru/" target='_blank'>Информационный портал о БАС</Link>
                                </li>
                                <li>
                                    <Link to="https://als-help.ru/" target='_blank'>Сайт о сложных решениях при БАС</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Контакты</h3>
                            <ul>
                                <li>
                                    <Link to="tel:+74953637174">+7 (495) 363-71-74</Link>
                                </li>
                                <li>
                                    <Link to="mailto:kurs@als-info.ru">kurs@als-info.ru</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>Мы в соцсетях</h3>
                            <div className="footer-social">
                                <Link to="https://vk.com/als.russia" target='_blank'>
                                    <img src={footervk} />
                                </Link>
                                <Link to="https://t.me/helpforpeoplenow" target="_blank">
                                    <img src={footertg} />
                                </Link>
                                <Link to="https://ok.ru/group/als.russia" target="_blank">
                                    <img src={footerok} />
                                </Link>
                                <Link to="https://rutube.ru/channel/38807184/" target="_blank">
                                    <img src={footerrurube} />
                                </Link>
                            </div>
                            <div className='popup-subscribe' onClick={()=>setPopupSubscribe(true)}>
                                Подписаться на рассылку
                            </div>
                        </div>
                    </div>
                    <div className="footer-polit">
                        <div className="row">
                        </div>
                    </div> 
                </div>
                <div className='footer-underline'>
                    <div className="content">
                        <div className="row">
                            <div className="col-md-8">
                                <div>
                                    <Link to="/privacy-policy/" target='_blank'>Политика конфиденциальности</Link>
                                </div>
                                <p className='footer-underline__text'>
                                    Фонд существует, чтобы развивать в России все виды помощи людям с БАС, а также пациентам с другими нейромышечными 
                                    заболеваниями. Мы помогаем пациентам, их близким и тем, кто помогает. Мы делаем все возможное, чтобы общество 
                                    узнало о проблемах людей с этими диагнозами.<br/>

                                    ИНН 7719417621; ОГРН 1157700009994
                                </p>
                            </div>
                            <div className="col-md-4">
                                <div className="">
                                    <div className='right'>
                                        <Link to="/user-agreement/" target='_blank'>Пользовательское соглашение</Link>
                                    </div>
                                    <div className="right">
                                        <Link to="/offer/" target='_blank'>Публичная оферта</Link>
                                    </div>
                                    <div className="right footer-underline__offer">2025 © БФ «Живи сейчас»</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {
                popupSubscribe ? (
                    <div className='popup-form'>
                        <div>
                            <div className="popup-info__close">
                                <img src={close} onClick={()=>{
                                    setPopupSubscribe(false)
                                }} />
                            </div>
                            {
                                !formSend ? (
                                    <form onSubmit={SubmitForm}>
                                        <p className='popup-form__description'>Укажите Email для получения рассылки</p>
                                        <label>
                                            <input 
                                                type="email" 
                                                placeholder='Email' 
                                                value={email}
                                                required
                                                onChange={(e)=>{
                                                    setEmail(e.target.value)
                                                }}
                                            />
                                        </label>
                                        <button className="orange-button">
                                            Подписаться
                                        </button>
                                    </form>
                                ):(
                                    <form onSubmit={SubmitForm}>
                                        <p 
                                            className='popup-form__description'
                                            style={{
                                                fontSize: 24,
                                                textAlign: "center"
                                            }}
                                        >
                                            Заявка успешно отправлена
                                        </p>
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }
        </>
    )
}